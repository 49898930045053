import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import translationEN from '../locales/en.json';
import translationFR from '../locales/fr.json';
import translationAR from '../locales/ar.json';
import translationES from '../locales/es.json';

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  ar: {
    translation: translationAR,
  },
  es: {
    translation: translationES,
  },
};

const supportedLanguages = process.env.REACT_APP_SUPPORTED_LANGUAGES?.split(',');
const allLanguages = Object.keys(resources);

const languages = supportedLanguages ? supportedLanguages.filter((lng) => allLanguages.includes(lng)) : allLanguages;

const languageDetector = new LanguageDetector();

languageDetector.addDetector({
  name: 'defaultDetector',
  lookup() {
    return process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'en';
  },
});

void i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'defaultDetector', 'navigator'],
      lookupLocalStorage: 'lng',
      lookupQuerystring: 'lng',
      lookupCookie: 'lng',
      caches: ['localStorage', 'cookie'],
    },
    supportedLngs: languages,
    fallbackLng: languages,
    keySeparator: '.',
    resources,
    debug: false,
    interpolation: {
      escapeValue: false,
      format: (value) => {
        if (value instanceof Date) {
          return value.toISOString();
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return value;
      },
    },
  });

export { i18n };

export default i18n;
