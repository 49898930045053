// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import React from 'react';

import './index.scss';
// this file should be listed in side effects in package.json
import './configs/i18next';

import ReactDom from 'react-dom/client';

import { AppWrapper, AuthObserver, MFE } from '@wf/base';
import * as WFComponents from '@wf/components';
import { setThemeParams } from '@wf/themes';

setThemeParams(process.env.REACT_APP_COUNTRY as string);

window.React = React;
window.ReactDom = ReactDom;
window.WFComponents = WFComponents;

const rootElement = document.getElementById('root');

if (rootElement) {
  void AuthObserver.init(process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string, process.env.PUBLIC_PATH as string);
  const root = ReactDom.createRoot(rootElement);

  root.render(
    <AppWrapper>
      {({ context, routerContext }) =>
        routerContext.selectedMenuLink ? <MFE routerContext={routerContext} context={context} /> : <div>Dashboard</div>
      }
    </AppWrapper>
  );
} else {
  alert('Invalid root rootElement');
}
